import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4f486258"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GenCodeCard" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_4 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "dialog-footer" }
const _hoisted_19 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ComboGrid = _resolveComponent("ComboGrid")!
  const _component_EditTable = _resolveComponent("EditTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_transfer = _resolveComponent("el-transfer")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "140px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "是否单据" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.form.billFlag,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.billFlag) = $event)),
                      placeholder: "请选择是否单据",
                      style: {"width":"100%"},
                      onChange: _ctx.billFlagChange
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "否",
                          value: "0"
                        }),
                        _createVNode(_component_el_option, {
                          label: "是",
                          value: "1"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "单据模块" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_cascader, {
                      modelValue: _ctx.form.billModel,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.billModel) = $event)),
                      disabled: _ctx.form.billFlag==0,
                      clearable: false,
                      placeholder: "请选择单据模块",
                      style: {"width":"100%"},
                      options: _ctx.billTypeTreeData,
                      onChange: _ctx.billTypeChange
                    }, null, 8, ["modelValue", "disabled", "options", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 8 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "归属应用",
                  prop: "application"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.form.application,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.application) = $event)),
                      placeholder: "请选择归属应用",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item,
                            label: item,
                            value: item
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "主表名称",
                  prop: "tbName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_cascader, {
                      ref: el=>_ctx.refMap.set('table',el),
                      props: {emitPath:false},
                      modelValue: _ctx.form.tbName,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.tbName) = $event)),
                      placeholder: "请选择主表名称",
                      style: {"width":"100%"},
                      options: _ctx.mainTbTreeData,
                      onChange: _ctx.change
                    }, {
                      default: _withCtx(({ node, data }) => [
                        _createElementVNode("span", null, _toDisplayString(data.label), 1),
                        (data.tbName)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(data.tbName) + ")", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue", "options", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "模块名称",
                  prop: "modelName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.form.modelName,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.modelName) = $event)),
                      maxlength: "50",
                      disabled: _ctx.form.billFlag==1,
                      placeholder: "请输入模块名称",
                      clearable: ""
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 11 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "模块描述",
                  prop: "modelCaption"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.form.modelCaption,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.modelCaption) = $event)),
                      maxlength: "50",
                      placeholder: "请输入模块描述",
                      clearable: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 11,
              offset: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "显示列数" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.form.cardCol,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.cardCol) = $event)),
                      placeholder: "请选择显示列数",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_option, {
                          label: "一列",
                          value: "1"
                        }),
                        _createVNode(_component_el_option, {
                          label: "两列",
                          value: "2"
                        }),
                        _createVNode(_component_el_option, {
                          label: "三列",
                          value: "3"
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "后台代码存放路径",
          prop: "javaPath"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.javaPath,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.javaPath) = $event)),
              maxlength: "180",
              placeholder: "请把路径设置到项目路径下,比如java、src",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "前代码存放路径",
          prop: "vuePath"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.vuePath,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.vuePath) = $event)),
              maxlength: "180",
              placeholder: "请输入前代码存放路径",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_tabs, { type: "border-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "关联子表",
          class: "childTable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('childTable',el)
            }, _ctx.detailGridParams({detailType:'childTable'}), {
              "parent-inst": this,
              afterDel: _ctx.afterDel
            }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "F_TABLE",
                  label: "子表名称"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.F_TABLE), 1))
                      : (_openBlock(), _createBlock(_component_el_cascader, {
                          key: 1,
                          ref: el=>_ctx.refMap.set('tableDetail',el),
                          props: {emitPath:false },
                          modelValue: scope.row.F_TABLE,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_TABLE) = $event),
                          options: _ctx.mainTbTreeData,
                          onChange: ($event: any) => (_ctx.tbDetailChange(scope.row))
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  prop: "F_MODEL_NAME",
                  label: "模块名称"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row.F_MODEL_NAME), 1))
                      : (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          placeholder: "请输入内容",
                          modelValue: scope.row.F_MODEL_NAME,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_MODEL_NAME) = $event),
                          maxlength: "20",
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "主表关联字段",
                  prop: "F_FIELD"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(scope.row.F_FIELD), 1))
                      : (_openBlock(), _createBlock(_component_ComboGrid, {
                          key: 1,
                          ref: el=>_ctx.refMap.set('fieldCombo',el),
                          modelValue: scope.row.F_FIELD,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD) = $event),
                          params: _ctx.fieldComboParam(scope.row.F_TABLE)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "params"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16, ["afterDel"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "列表查询字段",
          class: "queryFields"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('queryFields',el)
            }, _ctx.detailGridParams({detailType:'queryFields',clickEvent:'grid234AddHandler'}), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "查询字段",
                  prop: "F_FIELD"
                }),
                _createVNode(_component_el_table_column, {
                  label: "标题",
                  prop: "F_FIELD_CAPTION"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(scope.row.F_FIELD_CAPTION), 1))
                      : (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          placeholder: "请输入内容",
                          modelValue: scope.row.F_FIELD_CAPTION,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_CAPTION) = $event),
                          maxlength: "20",
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "字段类型",
                  prop: "F_FIELD_TYPE"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(scope.row.F_FIELD_TYPE), 1))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          modelValue: scope.row.F_FIELD_TYPE,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_TYPE) = $event),
                          placeholder: "请选择"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldType, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "列表显示字段",
          class: "listFields"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('listFields',el)
            }, _ctx.detailGridParams({detailType:'listFields',clickEvent:'grid234AddHandler'}), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "字段",
                  prop: "F_FIELD"
                }),
                _createVNode(_component_el_table_column, {
                  label: "标题",
                  prop: "F_FIELD_CAPTION"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(scope.row.F_FIELD_CAPTION), 1))
                      : (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          placeholder: "请输入内容",
                          modelValue: scope.row.F_FIELD_CAPTION,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_CAPTION) = $event),
                          maxlength: "20",
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "头对齐方式",
                  prop: "F_TITLE_ALIGN"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(scope.row.F_TITLE_ALIGN), 1))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          modelValue: scope.row.F_TITLE_ALIGN,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_TITLE_ALIGN) = $event),
                          placeholder: "请选择"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignType, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "值对齐方式",
                  prop: "F_VALUE_ALIGN"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(scope.row.F_VALUE_ALIGN), 1))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          modelValue: scope.row.F_VALUE_ALIGN,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_VALUE_ALIGN) = $event),
                          placeholder: "请选择"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignType, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "卡片显示字段",
          class: "cardFields"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('cardFields',el)
            }, _ctx.detailGridParams({detailType:'cardFields',clickEvent:'grid234AddHandler'}), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "字段",
                  prop: "F_FIELD"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "F_FIELD_CAPTION",
                  label: "标题"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(scope.row.F_FIELD_CAPTION), 1))
                      : (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          placeholder: "请输入内容",
                          modelValue: scope.row.F_FIELD_CAPTION,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_CAPTION) = $event),
                          maxlength: "20",
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "是否必填",
                  prop: "F_REQUIRED"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(scope.row.F_REQUIRED==0?'否':'是'), 1))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          modelValue: scope.row.F_REQUIRED,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_REQUIRED) = $event),
                          ref: el=>_ctx.refMap.set('cardFieldRequired',el)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yesNoSel, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "字段类型",
                  prop: "F_FIELD_TYPE"
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(scope.row.F_FIELD_TYPE), 1))
                      : (_openBlock(), _createBlock(_component_el_select, {
                          key: 1,
                          modelValue: scope.row.F_FIELD_TYPE,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_TYPE) = $event),
                          placeholder: "请选择"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardFieldType, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicDetailGridArr, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            label: item.tbCaption,
            key: item.tbName,
            class: _normalizeClass(item.tbName)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditTable, _mergeProps({
                ref_for: true,
                ref: el=>_ctx.refMap.set(item.tbName,el)
              }, _ctx.detailGridParams({detailType:item.tbName,clickEvent:'dynamicDetailGridAddHandler'}), { "parent-inst": this }), {
                tbCols: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: "字段",
                    prop: "F_FIELD"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "标题",
                    prop: "F_FIELD_CAPTION"
                  }, {
                    default: _withCtx((scope) => [
                      (!scope.row.EDIT)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(scope.row.F_FIELD_CAPTION), 1))
                        : (_openBlock(), _createBlock(_component_el_input, {
                            key: 1,
                            placeholder: "请输入内容",
                            modelValue: scope.row.F_FIELD_CAPTION,
                            "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_CAPTION) = $event),
                            maxlength: "20",
                            clearable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "字段类型",
                    prop: "F_FIELD_TYPE"
                  }, {
                    default: _withCtx((scope) => [
                      (!scope.row.EDIT)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(scope.row.F_FIELD_TYPE), 1))
                        : (_openBlock(), _createBlock(_component_el_select, {
                            key: 1,
                            modelValue: scope.row.F_FIELD_TYPE,
                            "onUpdate:modelValue": ($event: any) => ((scope.row.F_FIELD_TYPE) = $event),
                            placeholder: "请选择"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldType, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.value,
                                  label: item.label,
                                  value: item.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]))
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_table_column, {
                    label: "头对齐方式",
                    prop: "F_TITLE_ALIGN"
                  }, {
                    default: _withCtx((scope) => [
                      (!scope.row.EDIT)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(scope.row.F_TITLE_ALIGN), 1))
                        : (_openBlock(), _createBlock(_component_el_select, {
                            key: 1,
                            modelValue: scope.row.F_TITLE_ALIGN,
                            "onUpdate:modelValue": ($event: any) => ((scope.row.F_TITLE_ALIGN) = $event),
                            placeholder: "请选择"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignType, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.value,
                                  label: item.label,
                                  value: item.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]))
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_table_column, {
                    label: "值对齐方式",
                    prop: "F_VALUE_ALIGN"
                  }, {
                    default: _withCtx((scope) => [
                      (!scope.row.EDIT)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(scope.row.F_VALUE_ALIGN), 1))
                        : (_openBlock(), _createBlock(_component_el_select, {
                            key: 1,
                            modelValue: scope.row.F_VALUE_ALIGN,
                            "onUpdate:modelValue": ($event: any) => ((scope.row.F_VALUE_ALIGN) = $event),
                            placeholder: "请选择"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alignType, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.value,
                                  label: item.label,
                                  value: item.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 2
          }, 1032, ["label", "class"]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      title: "字段选择",
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      draggable: "",
      width: "60%",
      "append-to-body": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_18, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.chooseFieldsSureHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_transfer, {
          filterable: "",
          modelValue: _ctx.transformRightData,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.transformRightData) = $event)),
          data: _ctx.transformLeftData,
          titles: _ctx.titles
        }, null, 8, ["modelValue", "data", "titles"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      title: "生成种类选择",
      modelValue: _ctx.chooseTypeVisible,
      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.chooseTypeVisible) = $event)),
      draggable: "",
      width: "35%",
      "append-to-body": "",
      class: "genCodeType"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_19, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.chooseTypeSureHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_divider, { "content-position": "left" }, {
          default: _withCtx(() => [
            _createTextVNode("后端")
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("controller:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.controller,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.genTypeForm.controller) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("helper:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.helper,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.genTypeForm.helper) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("bean:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.bean,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.genTypeForm.bean) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("dao:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.dao,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.genTypeForm.dao) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("detailBean:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.detailBean,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.genTypeForm.detailBean) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("detailDao:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.detailDao,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.genTypeForm.detailDao) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.genTypeForm.showDetail]
        ]),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("buffer:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.buffer,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.genTypeForm.buffer) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_divider, { "content-position": "left" }, {
          default: _withCtx(() => [
            _createTextVNode("前端")
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("card:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.card,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.genTypeForm.card) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("cardHelper:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.cardHelper,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.genTypeForm.cardHelper) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("list:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.list,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.genTypeForm.list) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("listHelper:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.listHelper,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.genTypeForm.listHelper) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("地图:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.bmap,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.genTypeForm.bmap) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createTextVNode("report:")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  modelValue: _ctx.genTypeForm.report,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.genTypeForm.report) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}